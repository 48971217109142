/* Behavior */
.#{$stacked-menu-class-prefix}-has-collapsible {
  /** caret angle, Only show on collapsible mode */
  .has-child {
    > .menu-link::after {
      display: inline-block;
      content: '+';
      position: absolute;
      right: ($stacked-menu-padder*1.5);
      top: 50%;
      transform: translateY(-.5em);
      height: 1em;
      width: 1em;
      line-height: 1em;
      text-align: center;
    }

    &.has-active > .menu-link::after,
    &.has-open > .menu-link::after {
      content: '-'
    }
  }

  .has-child > .menu {
    visibility: visible;
    opacity: 1;
    overflow: hidden;
  }
  .has-child.has-open:not(.has-active) {
    > .menu {
      height: auto;
    }
  }
}

/** collapsible on compact mode */
.#{$stacked-menu-class-prefix}-has-collapsible.#{$stacked-menu-class-prefix}-has-compact {
  /** hide caret on level 1 */
  > .menu > .has-child {
    > .menu {
      transform-origin: 0 0;
      transform: scale(.6);
      transition: opacity 150ms linear, transform 150ms linear;
    }

    &.has-open > .menu {
      transform: scale(1);
    }

    > .menu-link:after {
      display: none;
    }
  }
  .has-child {
    &.has-open > .menu {
      position: absolute;
      width: 200px;
      top: 0;
      left: 100%;
      height: auto;
      visibility: visible;
      opacity: 1;
      border-radius: 0 $stacked-menu-border-radius $stacked-menu-border-radius 0;
    }
    > .menu .menu-link {
      padding-left: ($stacked-menu-padder * 2);
    }
  }
  /** child level 2 up */
  .menu .menu .has-child {
    &.has-open > .menu {
      position: static;
      width: auto;
      top: auto;
      left: auto;
    }
    > .menu {
      .menu-link {
        padding-left: ($stacked-menu-padder * 4);
      }
      .menu {
        .menu-link {
          padding-left: ($stacked-menu-padder * 6);
        }
      }
    }
  }
}
